<template>
  <VSSelectBase
    :id="props.id"
    :disabled="props.disabled"
    :readonly="props.readonly"
    :label="props.label"
    :label-tooltip="props.labelTooltip"
    :description="props.description"
    :open="props.open"
    :search="props.search"
    :search-placeholder="props.searchPlaceholder"
    :search-options="props.searchOptions"
    :allow-empty="props.allowEmpty"
    :options="options"
    :validation-result="validationResult"
    data-component-name="VSelect"
    :is-selected="isSelected"
    :is-any-selected="isAnySelected"
    @select="select"
    @add="add"
    @set="set"
  >
    <slot name="display">
      <template v-if="selectedOption">
        <VIcon v-if="selectedOption.icon" dense class="mr-2" :name="selectedOption.icon" />
        <div class="truncate" :title="String(selectedOption.label ?? selectedOption.value)">{{ selectedOption.label ?? selectedOption.value }}</div>
      </template>
      <template v-else>
        <div class="truncate text-neutral-400" :title="props.placeholder" v-text="props.placeholder || '&nbsp;'" />
      </template>
    </slot>
  </VSSelectBase>
</template>
<script lang="ts" generic="T" setup>
import { computed } from 'vue';
import VIcon from './../labels/VIcon.vue'
import VSSelectBase from './components/VSSelectBase.vue'
import { findSelectOption, type VSSelectBaseProps } from './helpers/select';
import type { V } from '@component-utils/types';
import { equal } from '@avvoka/shared';
import { createDefaultValidation, useValidation } from './helpers/validations';
import type { ValidationProps } from './helpers/types';

defineOptions({
  name: 'VSelect'
})

const props = defineProps<VSSelectBaseProps<T> & ValidationProps<T>>()

const modelValue = defineModel<undefined | null | T>({ required: true })

const { validationActive, validationResult } = useValidation(
  modelValue,
  props,
  createDefaultValidation<T>(props)
)

const isSelected = (option: V.Select.Item<T>) => equal(modelValue.value, option.value)

const options = computed(() => {
  const value = modelValue.value

  if (value && !findSelectOption(value, 'value', props.options)) {
    return [
      {
        value
      } as V.Select.Option<T>,
      ...props.options
    ]
  } else {
    return props.options
  }
})

const selectedOption = computed(() => findSelectOption(modelValue.value, 'value', props.options))

const isAnySelected = computed(() => !!modelValue.value)

const select = (value: T) => {
  modelValue.value = value

  validationActive.value = true
}

const add = (value: T) => {
  modelValue.value = value
}

const set = (values: T[], action: 'set' | 'select' | 'unselect') => {
  if (action === 'set') {
    modelValue.value = values[0]
  }

  validationActive.value = true
}
</script>